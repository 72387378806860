"use strict";

// Main navigation

const body = document.querySelector('body');

const langSwitcherToggle = document.querySelector('.toggle-language-switcher');
const langSwitcher = document.querySelector('.language-switcher');
const mainNavToggle = document.querySelector('.main-nav-toggle');
const mainNav = document.querySelector('.main-nav-outer');
const subMenuParents = document.querySelectorAll('.main-nav__list .parent');
const closeMainNav = document.querySelector('.main-nav-close');
const subMenus = document.querySelectorAll('.sub-menu');

mainNavToggle.addEventListener('click', function(){
  mainNav.classList.add('is-open');
});


subMenuParents.forEach(function(el) {
  const subMenuToggle = el.querySelector('a');
  const subMenu = el.querySelector('.sub-menu');

  subMenuToggle.addEventListener('click', function(e){
    let currentNav = false;
    if(e.target.classList.contains('is-active')){
      currentNav = true;
    }
    const subMenuParentLinks = document.querySelectorAll('.main-nav__list .parent > a');
    subMenuParentLinks.forEach(function(el){
      el.classList.remove('is-active');
    });
    subMenus.forEach(function(el){
      el.classList.remove('is-open');
    });
    if(!currentNav) {
      e.target.classList.add('is-active');
      subMenu.classList.add('is-open');
    }
  })
});

closeMainNav.addEventListener('click', function(){
  mainNav.classList.remove('is-open');

  subMenus.forEach(function(el){
    if(el.classList.contains('is-open')) {
      el.classList.remove('is-open');
    }
  });

})

hideOnClickOutside(mainNav);

langSwitcherToggle.addEventListener('click', function(){
  this.classList.toggle('is-active');
  langSwitcher.classList.toggle('is-open');
});

// Tiny sliders

// Solutions carousel

const solutionsCarousels = document.querySelectorAll('.solutions');

solutionsCarousels.forEach(function(el){

  const carousel = el.querySelector('div:first-child');

  if(carousel) {
    const carouselSlides = tns({
      container: carousel,
      mode: 'gallery',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      autoplayButtonOutput: false,
      speed: 500,
      controls: false,
      nav: true,
      mouseDrag: true,
      loop: true,
    });
  }
})

// Testimonials carousel

const testimonialsCarousel = document.querySelector('.testimonials div');
const testimonialsPrev = document.querySelector('.testimonials-control--prev');
const testimonialsNext = document.querySelector('.testimonials-control--next');

if(testimonialsCarousel) {

  const testimonialsCarouselSlides = tns({
    container: testimonialsCarousel,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    autoplayButtonOutput: false,
    speed: 1500,
    controls: true,
    nav: true,
    navPosition: 'bottom',
    mouseDrag: true,
    loop: true,
    prevButton: testimonialsPrev,
    nextButton: testimonialsNext,
  });

}

// Logos carousel

const logoSliders = document.querySelectorAll('.client-grid-outer');

logoSliders.forEach(logoSlider => {
  const logoSliderSlides = logoSlider.querySelector('.client-grid--slider');
  const logoSliderPrev = logoSlider.querySelector('.client-grid-control--prev');
  const logoSliderNext = logoSlider.querySelector('.client-grid-control--next');

  const logoSliderInner = tns({
    container: logoSliderSlides,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    autoplayButtonOutput: false,
    speed: 1500,
    controls: true,
    nav: false,
    navPosition: 'bottom',
    mouseDrag: true,
    loop: true,
    prevButton: logoSliderPrev,
    nextButton: logoSliderNext,
    responsive: {
      450: {
        items: 2,
      },
      768: {
        items: 4,
      },
      1024: {
        items: 6,
      }
    }
  });

  function disableSlider() {
    logoSliderInner.destroy();
  }

  if (logoSliderInner.getInfo().slideCount < logoSliderInner.getInfo().items) {
      disableSlider();
      logoSlider.classList.add('is-disabled');
  }

});

// Ligentix carousel

const ligentixCarousel = document.querySelector('.ligentix-carousel');
const ligentixNav = document.querySelector('.ligentix-carousel-nav');
const ligentixPrev = document.querySelector('.ligentix-carousel-control--prev');
const ligentixNext = document.querySelector('.ligentix-carousel-control--next');

if(ligentixCarousel) {

  const ligentixCarouselSlides = tns({
    container: ligentixCarousel,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    controls: true,
    nav: false,
    mouseDrag: true,
    loop: true,
    prevButton: ligentixPrev,
    nextButton: ligentixNext,
    nav: true,
    navContainer: ligentixNav,
    responsive: {
      1024: {
        loop: false
      }
    }
  });

}

//  Services list

var tabTriggerBtns = document.querySelectorAll('.service-info-toggle');

tabTriggerBtns.forEach(function (tabTriggerBtn) {
  tabTriggerBtn.addEventListener("click", function () {
    var currentTabData = document.querySelector(
      '.service-info[data-service-info="' + this.dataset.serviceToggle + '"]'
    );

    // remove classess
    document.querySelector(".service-info.is-visible").classList.remove("is-visible");
    document
      .querySelector(".service-info-toggle.is-active")
      .classList.remove("is-active");
    // add classes
    currentTabData.classList.add("is-visible");
    this.classList.add("is-active");
  });
});

// Filtering and pagination

const contentFeedFixed = document.querySelector('.related-content-feed--paginated');
const targetSelector = '.mix';

if (contentFeedFixed) {
  const mixer = mixitup(contentFeedFixed, {
    animation: {
      enable: false,
      animateResizeContainer: false
    },
    pagination: {
      limit: 9,
      hidePageListIfSinglePage: true,
      loop: true,
    },
    callbacks: {
      onPaginateStart: function(state) {
        const feed = document.querySelector('.related-content-feed');
        const feedTop = window.pageYOffset + feed.getBoundingClientRect().top;
        document.body.scrollTop = feedTop;
        document.documentElement.scrollTop = feedTop;
      }
    },
    selectors: {
      pageList: '.pagination',
      target: targetSelector
    },
    templates: {
      pagerPrev:
      '<div class="pt-xl"><button type="button" class="pagination-control pagination-control--prev ${classNames}" aria-label="Go to the previous page" data-page="prev"><svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="m28.57 100-7.14-7.14 42.86-42.86-42.86-42.86 7.14-7.14 50 50z"/></svg></button>',
      pagerNext:
        '<button type="button" class="pagination-control pagination-control--next ${classNames}" aria-label="Go to the next page" data-page="next"><svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="m28.57 100-7.14-7.14 42.86-42.86-42.86-42.86 7.14-7.14 50 50z"/></svg></button></div>',
      pager:
        '<button type="button" class="pagination-pg ${classNames}" data-page="${pageNumber}" aria-label="Navigate to page ${pageNumber}">${pageNumber}</button>'
    }
  });
}

const vacanciesList = document.querySelector('.vacancies-list');
const vacancySelector = '.mix';

if (vacanciesList) {
  const mixer = mixitup(vacanciesList, {
    animation: {
      enable: false,
      animateResizeContainer: false
    },
    selectors: {
      target: vacancySelector
    },
  });

  var filterSelect = $('#vacancy-filter');
  
  filterSelect.on('change', function(){
    mixer.filter(this.value);
    // mixer.mixitup('filter', );
  });
}

// Main navigation

function hideOnClickOutside(element) {
  // console.log(isVisible(element));
  const outsideClickListener = event => {
      if (!element.contains(event.target)) {
        
        subMenus.forEach(function(el){
          el.classList.remove('is-open');
        });
        
        const activeLinks = document.querySelectorAll('.main-nav__list a');

        activeLinks.forEach(function(el){
          el.classList.remove('is-active');
        });
      }
  }

  document.addEventListener('click', outsideClickListener);
}

// Content feed multi-style hover

const relatedFeedItems = document.querySelectorAll('.related-content-feed__item');

relatedFeedItems.forEach(function(el){
  const img = el.querySelector('.related-content-feed__img');
  img.addEventListener('mouseenter', function(){
    el.classList.add('is-hovering');
  })
  img.addEventListener('mouseleave', function(){
    el.classList.remove('is-hovering');
  })
});

const featuredPost = document.querySelector('.media-summary--featured');
const featuredPostImg = featuredPost.querySelector('.media-summary-media__img-link');

featuredPostImg.addEventListener('mouseenter', function () {
  featuredPost.classList.add('is-hovering');
});
featuredPostImg.addEventListener('mouseleave', function () {
  featuredPost.classList.remove('is-hovering');
});

// CF7 message duplication

const forms = document.querySelectorAll('.wpcf7-form');
document.addEventListener("DOMContentLoaded", function(){
  forms.forEach(function(form){
    const firstRow = form.querySelector('.form-row');
    const responseOutput = form.querySelector('.wpcf7-response-output');
    const clonedOutput = responseOutput.cloneNode(true);
    clonedOutput.classList.add('top');
    firstRow.after(clonedOutput);
    console.log(form);
  })
});

// Animated stats

const statsContainer = document.querySelector('.stats');
const stats = document.querySelectorAll('.stat__number');

if(statsContainer) {

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      stats.forEach(function(stat){
        const statVal = parseFloat(stat.getAttribute('data-value'));
        const countUp = new CountUp(stat, 0, statVal, 0, 5);
        if (entry.isIntersecting) {
          countUp.start();
        } else {
          countUp.reset();
        }
      });
    });
  });

  observer.observe(statsContainer);
}

// Language switcher

const inputs = document.querySelectorAll('.switcher-list__hide');

  // Function to add 'is-selected' class to the parent label
  function addIsSelectedClass(input) {
    const label = input.parentElement;
    label.classList.add("is-selected");
  }

  // Loop through the input elements and check initial state
  inputs.forEach(input => {
    if (input.checked) {
      addIsSelectedClass(input);
    }
  });

  // Add click event listeners to toggle the class
  inputs.forEach(input => {
    input.addEventListener("click", () => {
      inputs.forEach(otherInput => {
        // Remove 'is-selected' class from all labels
        otherInput.parentElement.classList.remove("is-selected");
      });

      // Add 'is-selected' class to the clicked input's parent label
      addIsSelectedClass(input);
    });
  });
